// Your web app's Firebase configuration

import { doc, getDoc, getFirestore } from "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDyHr7ccD2c4m57zTYuBCLPG2bkl0_9LWA",
  authDomain: "pickaapp-79307.firebaseapp.com",
  databaseURL: "https://pickaapp-79307.firebaseio.com",
  projectId: "pickaapp-79307",
  storageBucket: "pickaapp-79307.appspot.com",
  messagingSenderId: "751464223460",
  appId: "1:751464223460:web:4035d9ce7d55da5cde12f1",
  measurementId: "G-8QRQ0H1H3G"
};

export async function system(callback) {
  const firestore = getFirestore()
  
  const snapshot = await getDoc(doc(firestore, 'system/home'))

  if (snapshot.exists()) {
    callback(snapshot.data())
  }
}

export async function systemV2(app, callback) {
  const firestore = getFirestore(app)
  const snapshot = await getDoc(doc(firestore, 'system/home'))

  if (snapshot.exists()) {
    callback(snapshot.data())
  }
}


export { firebaseConfig }